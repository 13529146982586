<template>
  <div class="pagination-card">
    <div class="pagination-wrapper">
      <a
        id="button-prev"
        href="javascript:void(0)"
        class="pagination-btn"
        :class="{'disabled-pagination': +value <= 1}"
        @click="prev()"
      >
        <img
          :src="`/images/chevron_pagination.svg`"
          alt="left.png"
        ></a>
      <input
        :value="value"
        name="input-pagination"
        type="number"
        min="1"
        :max="max"
        v-bind="$attrs"
        :disabled="disable || totalOfPage === 0"
        @input="handleOnChange"
        @keyup.enter="handleReFetch"
        @blur="leave"
      >
      <a
        id="button-next"
        href="javascript:void(0)"
        class="pagination-btn"
        :class="{'disabled-pagination': +value === totalOfPage || totalOfPage === 0}"
        @click="next()"
      > <img
        :src="`/images/chevron_pagination.svg`"
        alt="right.png"
      > </a>
    </div>
    <div class="pagination-desc">
      dari {{ totalOfPage }} halaman
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputPagination',
  props: {
    disable: {
      type: Boolean,
      default: true,
    },
    perPage: { // please define this
      type: Number,
      required: true,
    },
    totalAllItem: { // please define this
      type: Number,
      required: true,
    },
    value: { // use v-model for this
      type: Number,
      default: 1, // default first page
    },
  },
  data() {
    return {
      valueChanged: null,
      max: 999,
    };
  },
  computed: {
    totalOfPage() {
      return Math.ceil(this.totalAllItem / this.perPage);
    },
  },
  methods: {
    prev() {
      if (+this.value >= 1) {
        this.$emit('prev', +this.value - 1);
      }
    },
    next() {
      if (+this.value !== this.totalOfPage && this.totalOfPage > 0) {
        this.$emit('next', +this.value + 1);
      }
    },
    handleOnChange(e) {
      // ignore comma, dot, dash, plus
      e.target.value = e.target.value.replace(/[\W_]+/, '');
      const result = e.target.value;
      if (+result > this.max) { // back to maximum
        e.target.value = this.max;
      }
      if (+result < 0 || +result > this.totalOfPage) {
        this.valueChanged = this.totalOfPage;
      } else {
        this.valueChanged = +result;
      }
    },
    handleReFetch() {
      // if user change the input, you need to re fetch the data of pagination
      if (this.valueChanged) {
        this.$emit('changeData', this.valueChanged);
      }
    },
    leave(e) {
      if (this.valueChanged === null || e.target.value.length === 0 || +e.target.value > this.totalOfPage) {
        e.target.value = this.value;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/style.scss";

@mixin set-box() {
  width: 40px;
  height: 27px;
  border-radius: 5px;
  box-sizing: border-box;
}

input[name='input-pagination'] {
  @include set-box();

  text-align: center;
  -webkit-appearance: none;
  border: 1px solid $color-gray-pensive-5;
  margin: 0 7%;
  color: black;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  background-color: transparent;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

}

.pagination-desc {
  font-style: normal;
  font-weight: 400;
  font-size: 10pt;
  line-height: 16pt;
  margin: auto 0;
  color: $color-gray-shade;
  padding-left: 10px;
}

.pagination-card {
  display: flex;
}

.pagination-wrapper {
  display: flex;
  flex-direction: row;
}

a {
  @include set-box();
  padding: 2.75px 9.5px;
  background: $color-gray-pensive;

  &.pagination-btn {
    img {
      position: relative;
      top: 2px;
      max-width: 150%;
    }

    &:first-of-type img {
      transform: rotate(-180deg);
      -webkit-transform: rotate(-180deg);
      -moz-transform: rotate(-180deg);
      -o-transform: rotate(-180deg);
      -ms-transform: rotate(-180deg);
    }
  }
}

.disabled-pagination {
  cursor: not-allowed !important;
}
</style>
